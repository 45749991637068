@import "./variables.scss";

@import "../assets/fonts/inter";
@import "../assets/fonts/material-icons";

$inter: "Inter", sans-serif;

html,
body {
  font-size: 16px;
  font-family: $inter;
}

h1 {
  font-size: 2.5rem;
  font-weight: 900;
}

h2 {
  font-size: 2rem;
  //line-height: 2.7rem;
  font-weight: 600;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
}

h4 {
  font-size: 1.1rem;
  line-height: 1rem;
}

a {
  line-height: 24px;
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}
