/* You can add global styles to this file, and also import other style files */
@import "./colors";
@import "./theme.scss";
@import "./typography.scss";
@import "./breakpoints.scss";
@import "@vi-succ/design-system-web/styles/dsw.css";

html,
body {
  height: 100%;
  margin: 0;
}

button.mat-mdc-button,
button.mat-mdc-raised-button,
button.mat-mdc-stroked-button {
  font-size: 16px;
  line-height: 40px;
  border-radius: 160px;
  padding: 0 30px;
}

.whitespace {
  white-space: pre-wrap;
}

//Search bar scss for autocomplete & spinner because of view encapsulation
.autocomplete {
  max-height: 80vh !important;
}

.autocomplete-spinner circle {
  stroke: white;
}

$transitionTime: 0.3s 0s;

.input-transition-style {
  transition: stroke $transitionTime;
}

.product-search-input {
  @extend .input-transition-style;

  .mat-mdc-progress-spinner circle {
    stroke: white;
  }
}

.product-search-input-focused {
  @extend .input-transition-style;

  .mat-mdc-progress-spinner circle {
    stroke: black;
  }
}

//Clickable list entries
tr.mat-mdc-row.row-clickable:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
